import { Employee, RateableResult } from '../../interfaces/rest';

const API_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

const getAuthHeaders = (token: string) => ({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
});

export const searchEmployees = async (
    token: string,
    query: string
): Promise<number> => {
    const response = await fetch(`${API_BASE_URL}api/search`, {
        method: 'POST',
        headers: getAuthHeaders(token),
        body: JSON.stringify({ query }),
    });

    if (!response.ok) {
        throw new Error('Failed to search employees');
    }

    const jsonResponse = await response.json();

    console.log(jsonResponse);
    return jsonResponse.id;
};

export const fetchSearchResult = async (
    token: string,
    searchId: number
): Promise<RateableResult[]> => {
    const aiSearchResponse = await fetch(
        `${API_BASE_URL}api/aiSearches/${searchId}?projection=fullAiSearch`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );

    if (!aiSearchResponse.ok) {
        throw new Error('Failed to fetch aiSearch');
    }

    const aiSearchJson = await aiSearchResponse.json();
    const ratableResults = aiSearchJson.ratableResults;

    // Parse the employeeSnapshot data field into an Employee object
    const parsedRateableResults: RateableResult[] = ratableResults.map(
        (result: any) => {
            const employeeSnapshotDataString = result.employeeSnapshot.data;
            let employeeSnapshot: Employee;
            try {
                employeeSnapshot = JSON.parse(employeeSnapshotDataString);
            } catch (error) {
                console.error(
                    `Failed to parse employeeSnapshot data for result ID ${result.id}`,
                    error
                );

                employeeSnapshot = {
                    id: 0,
                    oidcUid: '',
                    email: '',
                    title: '',
                    firstName: '',
                    lastName: '',
                    education: '',
                    yearOfBirth: 0,
                    nationality: '',
                    languages: [],
                    workExperience: '',
                    keyAspects: '',
                    qualification: '',
                    assignments: [],
                    itExperience: '',
                    skills: [],
                    certifications: '',
                    training: '',
                };
            }

            const rateableResult: RateableResult = {
                id: result.id,
                employeeId: result.employeeId,
                score: result.score,
                rating: result.rating,
                aiSearch: result.aiSearch,
                employeeSnapshot: employeeSnapshot,
            };

            return rateableResult;
        }
    );

    return parsedRateableResults;
};

export const fetchEmployeeById = async (
    token: string,
    id: number
): Promise<Employee> => {
    const employeeResponse = await fetch(
        `${API_BASE_URL}api/employees/${id}?projection=fullEmployee`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );

    if (!employeeResponse.ok) {
        throw new Error(`Failed to fetch employee with id ${id}`);
    }

    const employee = await employeeResponse.json();
    return employee;
};

export const patchRating = async (
    token: string,
    rateableResultId: number,
    rating: number
) => {
    try {
        const response = await fetch(
            `${API_BASE_URL}api/ratableResults/${rateableResultId}`,
            {
                method: 'PATCH',
                headers: getAuthHeaders(token),
                body: JSON.stringify({ rating }),
            }
        );

        if (!response.ok) {
            throw new Error('Failed to update rating');
        }
    } catch (error) {
        console.error('Error updating rating:', error);
    }
};
