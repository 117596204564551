import React from 'react';

export default function Verwaltung(): React.ReactElement {
    return (
        <>
            <h1>Verwaltung</h1>
            <p>Hier Verwaltung einfügen</p>
        </>
    );
}
