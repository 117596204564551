import { FullEmployee, Skill } from '../../interfaces/rest';
import { MappingRef } from '../../interfaces/contractorMappingRef';

const API_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

const getAuthHeaders = (token: string) => ({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
});

export const fetchEmployees = async (
    token: string,
    selectedSkills: { skillName: string; experience: number }[],
    observationTime?: string
): Promise<FullEmployee[]> => {
    const params = new URLSearchParams();

    // experience parameter aufbauen
    if (selectedSkills.length > 0) {
        const experienceParam = selectedSkills
            .map(skillFilter => {
                if (skillFilter.experience > 0) {
                    return `${skillFilter.skillName}>=${skillFilter.experience}`;
                } else {
                    return `${skillFilter.skillName}`;
                }
            })
            .join(',');
        params.append('experience', experienceParam);
    }

    // observationTime hat noch keinen input
    if (observationTime) {
        params.append('observationTime', observationTime);
    }

    const queryString = params.toString();

    const response = await fetch(
        `${API_BASE_URL}api/skillfilter${queryString ? `?${queryString}` : ''}`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to fetch employees');
    }

    const jsonResponse = await response.json();
    return jsonResponse;
};

export const fetchSkills = async (token: string): Promise<Skill[]> => {
    const response = await fetch(`${API_BASE_URL}api/skills`, {
        method: 'GET',
        headers: getAuthHeaders(token),
    });

    if (!response.ok) {
        throw new Error('Failed to fetch skills');
    }

    const jsonResponse = await response.json();
    return jsonResponse._embedded.skills;
};

export const exportEmployee = async (
    token: string,
    employeeId: number,
    mapping: MappingRef
): Promise<void> => {
    const response = await fetch(
        `${API_BASE_URL}api/export?employeeId=${employeeId}&mapping=${encodeURIComponent(mapping)}`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to export employee');
    }

    const blob = await response.blob();

    const contentDisposition = response.headers.get('Content-Disposition');
    let fileName = 'exported_employee.docx';

    if (contentDisposition && contentDisposition.includes('filename=')) {
        fileName = contentDisposition.split('filename=')[1].trim();

        if (fileName.startsWith('"') && fileName.endsWith('"')) {
            fileName = fileName.substring(1, fileName.length - 1);
        }
    }

    const newBlob = new Blob([blob], {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });

    const url = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
};
