import React, { useState } from 'react';
import {
    TextField,
    Button,
    Grid,
    MenuItem,
    Box,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Autocomplete,
} from '@mui/material';
import { Skill } from '../../../interfaces/rest';
import { AssignmentsInputProps } from './AssignmentsInputProps';

export const Assignments: React.FC<AssignmentsInputProps> = ({
    skills,
    handleAddProject,
    handleEditProject,
    handleDeleteProject,
    assignments = [],
}) => {
    const [assignment, setAssignment] = useState<any>({
        id: 0,
        mission: '',
        role: '',
        project: {
            id: 0,
            name: '',
            client: '',
            sector: '',
        },
        tasks: '',
        skills: [],
        contacts: '',
        period: {
            entry: {
                year: 0,
                month: 0,
            },
            exit: {
                year: 0,
                month: 0,
            },
        },
        teamSize: 0,
    });

    const [selectedSkill, setSelectedSkill] = useState<Skill | null>(null);

    const [errors, setErrors] = useState({
        role: '',
        tasks: '',
        projectName: '',
        client: '',
        sector: '',
        entryMonth: '',
        entryYear: '',
        skills: '',
    });

    const [editMode, setEditMode] = useState<boolean>(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        if (name.startsWith('project.')) {
            const projectField = name.split('.')[1];
            setAssignment((prev: { project: any }) => ({
                ...prev,
                project: {
                    ...prev.project,
                    [projectField]: value,
                },
            }));
        } else if (name.startsWith('period.entry.')) {
            const entryField = name.split('.')[2];
            setAssignment((prev: { period: { entry: any } }) => ({
                ...prev,
                period: {
                    ...prev.period,
                    entry: {
                        ...prev.period.entry,
                        [entryField]: parseInt(value, 10),
                    },
                },
            }));
        } else if (name.startsWith('period.exit.')) {
            const exitField = name.split('.')[2];
            setAssignment((prev: { period: { exit: any } }) => ({
                ...prev,
                period: {
                    ...prev.period,
                    exit: {
                        ...prev.period.exit,
                        [exitField]: parseInt(value, 10),
                    },
                },
            }));
        } else {
            setAssignment((prev: any) => ({
                ...prev,
                [name]: value,
            }));
        }
    };

    const handleAddSkill = () => {
        if (selectedSkill) {
            setAssignment((prev: { skills: any[] }) => {
                const skillExists = prev.skills.some(
                    skill => skill.id === selectedSkill.id
                );

                if (skillExists) {
                    alert('Dieser Skill wurde bereits hinzugefügt.');
                    return prev;
                }
                return {
                    ...prev,
                    skills: [
                        ...prev.skills,
                        {
                            id: selectedSkill.id,
                            name: selectedSkill.name,
                            description: selectedSkill.description,
                            type: selectedSkill.type,
                        },
                    ],
                };
            });
            setSelectedSkill(null);
        }
    };

    const handleDeleteSkill = (index: number) => {
        setAssignment((prev: { skills: any[] }) => ({
            ...prev,
            skills: prev.skills.filter((_, i) => i !== index),
        }));
    };

    const validateFields = () => {
        let newErrors = {
            role: '',
            tasks: '',
            projectName: '',
            client: '',
            sector: '',
            entryMonth: '',
            entryYear: '',
            skills: '',
        };

        let isValid = true;

        if (!assignment.role) {
            newErrors.role = 'Role is required';
            isValid = false;
        }
        if (!assignment.tasks) {
            newErrors.tasks = 'Tasks are required';
            isValid = false;
        }
        if (!assignment.project.name) {
            newErrors.projectName = 'Project name is required';
            isValid = false;
        }
        if (!assignment.project.client) {
            newErrors.client = 'Client is required';
            isValid = false;
        }
        if (!assignment.project.sector) {
            newErrors.sector = 'Sector is required';
            isValid = false;
        }
        if (!assignment.period.entry.month) {
            newErrors.entryMonth = 'Entry month is required';
            isValid = false;
        }
        if (!assignment.period.entry.year) {
            newErrors.entryYear = 'Entry year is required';
            isValid = false;
        }
        if (assignment.skills.length === 0) {
            newErrors.skills = 'At least one skill is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleAddAssignment = () => {
        if (validateFields()) {
            if (editMode && editIndex !== null) {
                handleEditProject(assignment, editIndex);
                setEditMode(false);
                setEditIndex(null);
            } else {
                handleAddProject(assignment);
            }

            setAssignment({
                id: 0,
                mission: '',
                role: '',
                project: {
                    id: 0,
                    name: '',
                    client: '',
                    sector: '',
                },
                tasks: '',
                skills: [],
                contacts: '',
                period: {
                    entry: {
                        year: 0,
                        month: 0,
                    },
                    exit: {
                        year: 0,
                        month: 0,
                    },
                },
                teamSize: 0,
            });
            setErrors({
                role: '',
                tasks: '',
                projectName: '',
                client: '',
                sector: '',
                entryMonth: '',
                entryYear: '',
                skills: '',
            });
        }
    };

    const handleEditAssignment = (index: number) => {
        const assignmentToEdit = assignments[index];

        const mappedSkills = assignmentToEdit.skills.map((skillE: Skill) => {
            const foundSkill = skills.find(skill => skill.id === skillE.id);
            return {
                ...skillE,
                name: foundSkill ? foundSkill.name : 'Unknown Skill',
            };
        });

        setAssignment({ ...assignmentToEdit, skills: mappedSkills });
        setEditMode(true);
        setEditIndex(index);
    };

    const handleDeleteAssignment = (index: number) => {
        handleDeleteProject(index);
    };

    return (
        <Box
            sx={{
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <Typography variant='h6' gutterBottom>
                {editMode ? 'Edit Project' : 'Add New Project'}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='project.name'
                        label='Project Name'
                        value={assignment.project.name}
                        onChange={handleInputChange}
                        error={!!errors.projectName}
                        helperText={errors.projectName}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='project.client'
                        label='Client'
                        value={assignment.project.client}
                        onChange={handleInputChange}
                        error={!!errors.client}
                        helperText={errors.client}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='project.sector'
                        label='Sector'
                        value={assignment.project.sector}
                        onChange={handleInputChange}
                        error={!!errors.sector}
                        helperText={errors.sector}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='role'
                        label='Role'
                        value={assignment.role}
                        onChange={handleInputChange}
                        error={!!errors.role}
                        helperText={errors.role}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='mission'
                        label='Mission'
                        value={assignment.mission}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name='tasks'
                        label='Tasks'
                        multiline
                        minRows={3}
                        value={assignment.tasks}
                        onChange={handleInputChange}
                        error={!!errors.tasks}
                        helperText={errors.tasks}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='contacts'
                        label='Contacts'
                        value={assignment.contacts}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='teamSize'
                        label='Team Size'
                        type='number'
                        value={assignment.teamSize || ''}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        select
                        fullWidth
                        name='period.entry.month'
                        label='Entry Month'
                        value={assignment.period.entry.month || ''}
                        onChange={handleInputChange}
                        error={!!errors.entryMonth}
                        helperText={errors.entryMonth}
                    >
                        {[...Array(12)].map((_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                                {new Date(0, index).toLocaleString('default', {
                                    month: 'long',
                                })}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='period.entry.year'
                        label='Entry Year'
                        type='number'
                        value={assignment.period.entry.year || ''}
                        onChange={handleInputChange}
                        error={!!errors.entryYear}
                        helperText={errors.entryYear}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        select
                        fullWidth
                        name='period.exit.month'
                        label='Exit Month'
                        value={assignment.period.exit.month || ''}
                        onChange={handleInputChange}
                    >
                        {[...Array(12)].map((_, index) => (
                            <MenuItem key={index + 1} value={index + 1}>
                                {new Date(0, index).toLocaleString('default', {
                                    month: 'long',
                                })}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name='period.exit.year'
                        label='Exit Year'
                        type='number'
                        value={assignment.period.exit.year || ''}
                        onChange={handleInputChange}
                    />
                </Grid>

                <Grid item xs={12} sx={{ mt: 4 }}>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid item xs={8}>
                            <Autocomplete
                                value={selectedSkill}
                                onChange={(event, newValue) =>
                                    setSelectedSkill(newValue)
                                }
                                options={skills}
                                getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label='Select Skill'
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                onClick={handleAddSkill}
                                variant='contained'
                                fullWidth
                                sx={{
                                    height: 56,
                                    boxShadow: 1,
                                }}
                            >
                                Add Skill
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Skill</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Delete</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assignment.skills.map(
                                    (
                                        skill: {
                                            name: string;
                                        },
                                        index: number
                                    ) => (
                                        <TableRow key={index}>
                                            <TableCell>{skill.name}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant='outlined'
                                                    color='secondary'
                                                    onClick={() =>
                                                        handleDeleteSkill(index)
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {errors.skills && (
                        <Typography color='error' sx={{ mt: 2 }}>
                            {errors.skills}
                        </Typography>
                    )}
                </Grid>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        mt: 4,
                    }}
                >
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={handleAddAssignment}
                    >
                        {editMode ? 'Save Changes' : 'Add Project'}
                    </Button>
                </Box>

                <Grid item xs={12}>
                    <TableContainer component={Paper} sx={{ mt: 4 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <strong>Project Name</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Edit</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Delete</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assignments.map((assignment, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            {assignment.project.name}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant='outlined'
                                                onClick={() =>
                                                    handleEditAssignment(index)
                                                }
                                            >
                                                Edit
                                            </Button>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant='outlined'
                                                color='error'
                                                onClick={() =>
                                                    handleDeleteAssignment(
                                                        index
                                                    )
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    );
};
