import { Skill } from '../../interfaces/rest';

const API_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

const getAuthHeaders = (token: string) => ({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
});

export const fetchSkills = async (token: string): Promise<Skill[]> => {
    const response = await fetch(`${API_BASE_URL}api/skills`, {
        method: 'GET',
        headers: getAuthHeaders(token),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const jsonResponse = await response.json();
    return jsonResponse._embedded.skills;
};

export const fetchEmployee = async (
    token: string,
    call: string
): Promise<any> => {
    const response = await fetch(
        `${API_BASE_URL}api/employees/search/${call}`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    const jsonResponse = await response.json();
    console.log(jsonResponse);

    if (jsonResponse._embedded.employees.length > 0) {
        return jsonResponse._embedded.employees[0];
    }
    return null;
};
