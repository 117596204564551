import React, { useState, useEffect } from 'react';
import {
    Container,
    TextField,
    Button,
    CircularProgress,
    Box,
} from '@mui/material';
import { ContractorMapping, RateableResult } from '../../interfaces/rest';
import { searchEmployees, fetchSearchResult, patchRating } from './searchApi';
import { fetchMappings } from '../glossar/skillApi';
import { exportEmployee } from '../export/exportApi';
import { useAuth } from 'react-oidc-context';
import RateableResultsTable from './rateableResultsTable';
import { MappingRef } from '../../interfaces/contractorMappingRef';

export default function SearchForm(): React.ReactElement {
    const [query, setQuery] = useState<string>('');
    const [rateableResults, setRateableResults] = useState<RateableResult[]>(
        []
    );
    const [mappings, setMappings] = useState<ContractorMapping[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [mappingsLoading, setMappingsLoading] = useState<boolean>(true);
    const auth = useAuth();

    useEffect(() => {
        const loadMappings = async () => {
            try {
                const mappingsData = await fetchMappings(
                    auth.user?.access_token || ''
                );
                setMappings(mappingsData);
            } catch (error) {
                console.error(error);
                alert('Error loading mappings');
            } finally {
                setMappingsLoading(false);
            }
        };
        loadMappings();
    }, [auth.user?.access_token]);

    const handleSearch = async () => {
        setLoading(true);
        setRateableResults([]);
        try {
            // Send search query and save results in DB
            const searchId = await searchEmployees(
                auth.user?.access_token || '',
                query
            );
            console.log(searchId);
            const rateableResults = await fetchSearchResult(
                auth.user?.access_token || '',
                searchId
            );
            rateableResults.sort((a, b) => b.score - a.score);
            setRateableResults(rateableResults);
        } catch (error) {
            console.error(error);
            alert('Error during search');
        } finally {
            setLoading(false);
        }
    };

    const handleExport = async (employeeId: number, mappingRef: MappingRef) => {
        try {
            await exportEmployee(
                auth.user?.access_token || '',
                employeeId,
                mappingRef,
            );
            alert('Employee exported successfully');
        } catch (error) {
            console.error(error);
            alert('Failed to export employee');
        }
    };

    const handleRatingUpdate = async (
        rateableResultId: number,
        rating: number
    ) => {
        try {
            await patchRating(
                auth.user?.access_token || '',
                rateableResultId,
                rating
            );
            alert('Result rated successfully');
        } catch (error) {
            console.error('Error rating result:', error);
        }
    };

    return (
        <Container sx={{ paddingTop: 4 }}>
            <h1>Search</h1>
            <TextField
                label='Search Query'
                multiline
                rows={4}
                fullWidth
                value={query}
                onChange={e => setQuery(e.target.value)}
                variant='outlined'
                margin='normal'
            />
            <Button
                variant='contained'
                color='primary'
                onClick={handleSearch}
                sx={{ marginTop: 2 }}
                disabled={loading || mappingsLoading}
            >
                Search
            </Button>

            {(loading || mappingsLoading) && (
                <Box sx={{ textAlign: 'center', paddingTop: 4 }}>
                    <CircularProgress />
                </Box>
            )}

            {rateableResults.length > 0 && mappings.length > 0 && (
                <Box sx={{ marginTop: 4 }}>
                    <RateableResultsTable
                        rateableResults={rateableResults}
                        mappings={mappings}
                        onExport={handleExport}
                        onRatingUpdate={handleRatingUpdate}
                    />
                </Box>
            )}
        </Container>
    );
}
