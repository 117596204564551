import React, { useState, ChangeEvent } from 'react';
import {
    Button,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TablePagination,
    Paper,
    Autocomplete,
    TextField,
    Stack,
    Typography,
} from '@mui/material';
import { Skill } from '../../../interfaces/rest';
import { SkillsTableInputProps } from './SkillsTableInputProps';

export const SkillsTable: React.FC<SkillsTableInputProps> = ({
    employeeData,
    handleDeleteSkill,
    handleAddSkill,
    handleUpdateLanguageLevel,
    skillsTable,
    skills,
}) => {
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [skill, setSkill] = useState<Skill | null>(null);
    const [error, setError] = useState<string>('');

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleLevelChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        skillId: number
    ) => {
        const newLevel = event.target.value;
        handleUpdateLanguageLevel(skillId, newLevel);
    };

    const handleAddSkillClick = () => {
        if (!skill) {
            setError('Please select a skill first');
        } else {
            setError('');
            handleAddSkill(skill);
        }
    };

    return (
        <>
            <Stack
                direction='row'
                justifyContent='space-between'
                sx={{ mt: 2, mb: 2 }}
            >
                <Autocomplete
                    value={skill ?? null}
                    onChange={(event: any, newValue: Skill | null) =>
                        setSkill(newValue)
                    }
                    disablePortal
                    options={skills}
                    sx={{ width: 300 }}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                        <TextField {...params} label='Select Skill' />
                    )}
                />
                <Button
                    onClick={handleAddSkillClick}
                    variant='contained'
                    sx={{ ml: 2 }}
                >
                    Add Skill
                </Button>
            </Stack>

            {error && (
                <Typography color='error' sx={{ mb: 2 }}>
                    {error}
                </Typography>
            )}

            <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                        <TableRow>
                            <TableCell>
                                <strong>Name</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Description</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Level</strong>
                            </TableCell>
                            <TableCell align='right'>
                                <strong>Actions</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {skillsTable.map((skill, skillIndex) => (
                            <TableRow key={skill.id}>
                                <TableCell>{skill.name}</TableCell>
                                <TableCell>{skill.description}</TableCell>
                                <TableCell>
                                    {skill.type === 'LANGUAGE' ? (
                                        <TextField
                                            value={
                                                employeeData.languages.find(
                                                    (lang: {
                                                        skillId: number;
                                                    }) =>
                                                        lang.skillId ===
                                                        skill.id
                                                )?.level || ''
                                            }
                                            onChange={event =>
                                                handleLevelChange(
                                                    event,
                                                    skill.id
                                                )
                                            }
                                            label='Level'
                                            variant='outlined'
                                            size='small'
                                        />
                                    ) : (
                                        ''
                                    )}
                                </TableCell>
                                <TableCell align='right'>
                                    <Button
                                        onClick={() =>
                                            handleDeleteSkill(skillIndex, skill)
                                        }
                                        variant='outlined'
                                        color='secondary'
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component='div'
                    count={skillsTable.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
};
