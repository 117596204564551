import React, { useState } from 'react';
import {
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Tabs,
    Tab,
    Box,
    TablePagination,
    Button,
    Select,
    MenuItem,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { useAuth } from 'react-oidc-context';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useQuery } from '@tanstack/react-query';
import { Skill, SkillType, ContractorMapping } from '../../interfaces/rest';
import AddContractorMappingForm from './addContractorMappingForm';
import {
    fetchSkills,
    createContractorMapping,
    fetchMappings,
    deleteContractorMapping,
    patchContractorMapping,
    createCompetency,
    saveCompetency,
} from './skillApi';

export default function ContractorMappingTable(): React.ReactElement {
    const [skills, setSkills] = useState<Skill[]>([]);
    const [contractorMappings, setContractorMappings] = useState<
        ContractorMapping[]
    >([]);
    const [selectedTab, setSelectedTab] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [formVisible, setFormVisible] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [selectedMappingId, setSelectedMappingId] = useState<number>(0);
    const [currentMappingEdit, setCurrentMappingEdit] =
        useState<ContractorMapping | null>(null);
    const [duplicateMapping, setDuplicateMapping] = useState<string | null>(
        null
    );
    const auth = useAuth();
    const skillTypes: SkillType[] = ['IT', 'LANGUAGE', 'CONSULTING'];

    const {
        isPending: isPendingSkill,
        isError: isErrorSkill,
        error: errorSkill,
    } = useQuery({
        queryKey: ['glossaryGet'],
        queryFn: async () => {
            const fetchedSkills = await fetchSkills(
                auth.user?.access_token || ''
            );
            fetchedSkills.sort((a, b) => a.name.localeCompare(b.name));
            setSkills(fetchedSkills);
            return fetchedSkills;
        },
    });

    const { refetch } = useQuery({
        queryKey: ['mappingGet'],
        queryFn: async () => {
            const fetchedMappings = await fetchMappings(
                auth.user?.access_token || ''
            );
            fetchedMappings.forEach(mapping => {
                if (!Array.isArray(mapping.competencies)) {
                    mapping.competencies = [];
                }
                if (!Array.isArray(mapping.categories)) {
                    mapping.categories = [];
                }
            });
            setContractorMappings(fetchedMappings);
            return fetchedMappings;
        },
    });

    const isMappingNameUnique = (name: string, id?: number) => {
        return !contractorMappings.some(
            mapping =>
                mapping.name.toLowerCase() === name.toLowerCase() &&
                mapping.id !== id
        );
    };

    const handleAddContractorMapping = async (
        name: string,
        categories: string[]
    ) => {
        if (!isMappingNameUnique(name, currentMappingEdit?.id)) {
            setDuplicateMapping(`Mapping for "${name}" already exists.`);
            return;
        }

        setDuplicateMapping(null);
        console.log('categories: ', categories);
        if (currentMappingEdit) {
            if (
                currentMappingEdit.name === name &&
                currentMappingEdit.categories === categories
            ) {
                console.log('no changes');
                setCurrentMappingEdit(null);
                return;
            }
            const updatedMapping: ContractorMapping = {
                ...currentMappingEdit,
                name: name,
                categories: categories,
            };
            console.log('Updated Mapping: ', updatedMapping);
            try {
                await patchContractorMapping(
                    auth.user?.access_token || '',
                    updatedMapping
                );
                setCurrentMappingEdit(null);
                refetch();
            } catch (error) {
                console.error('Failed to edit contractor mapping:', error);
            }
            return;
        }

        const newMappingData: Omit<ContractorMapping, 'id'> = {
            name,
            competencies: [],
            categories,
        };

        try {
            await createContractorMapping(
                auth.user?.access_token || '',
                newMappingData
            );
            refetch();
        } catch (error) {
            console.error('Failed to create contractor mapping:', error);
        }
    };

    const handleChangeCategory = async (
        skillId: number,
        mappingId: number,
        category: string
    ) => {
        const mapping = contractorMappings?.find(
            mapping => mapping.id === mappingId
        );
        if (!mapping) {
            console.error(`Mapping with ID ${mappingId} not found.`);
            return;
        }

        let competency = mapping.competencies?.find(
            comp => comp.skill.id === skillId
        );

        if (competency) {
            competency.category = category;
            try {
                await saveCompetency(auth.user?.access_token || '', competency);
                console.log(`Updated mapping with ID ${mappingId}`);
                refetch();
            } catch (error) {
                console.error(`Failed to update mapping: ${error}`);
                refetch();
            }
        } else {
            try {
                const newCompetency = await createCompetency(
                    auth.user?.access_token || '',
                    category,
                    skillId
                );

                mapping.competencies.push(newCompetency);

                await patchContractorMapping(
                    auth.user?.access_token || '',
                    mapping
                );
                console.log(
                    `Added new competency to mapping with ID ${mappingId}`
                );
                refetch();
            } catch (error) {
                console.error(
                    `Failed to create or update competency: ${error}`
                );
            }
        }
    };
    const handleDeleteContractorMapping = async (mappingId: number) => {
        try {
            await deleteContractorMapping(
                auth.user?.access_token || '',
                mappingId
            );
        } catch (error) {
            console.error('Failed to delete contractor mapping:', error);
        }
    };

    const handleClickOpen = (mappingId: number) => {
        setSelectedMappingId(mappingId);
        setOpen(true);
    };

    const handleEditMapping = (mapping: ContractorMapping) => {
        setCurrentMappingEdit(mapping);
        setFormVisible(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMappingId(0);
    };

    const handleDelete = async () => {
        if (selectedMappingId !== 0) {
            try {
                await handleDeleteContractorMapping(selectedMappingId);
                refetch();
            } catch (error) {
                console.error('Failed to delete contractor mapping:', error);
            } finally {
                handleClose();
            }
        }
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredSkills = (type: SkillType) =>
        skills.filter(skill => skill.type === type);

    const displaySkills = (type: SkillType) =>
        filteredSkills(type).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );

    const toggleFormVisibility = () => {
        setFormVisible(!formVisible);
    };

    if (isPendingSkill) return <div>Loading skill data...</div>;
    if (isErrorSkill)
        return <div>An error has occurred: {errorSkill.message}</div>;

    return (
        <Container sx={{ paddingTop: 4 }}>
            <Typography variant='h4' gutterBottom align='center'>
                Glossar
            </Typography>
            <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                indicatorColor='primary'
                textColor='primary'
                centered
            >
                {skillTypes.map(type => (
                    <Tab key={type} label={type} />
                ))}
            </Tabs>
            <Box sx={{ paddingTop: 4 }}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={toggleFormVisibility}
                >
                    {formVisible ? 'Hide Forms' : 'Add Contractor Mapping'}
                </Button>
                {formVisible && (
                    <Box sx={{ marginTop: 2 }}>
                        <AddContractorMappingForm
                            onAddMapping={handleAddContractorMapping}
                            alertMessage={duplicateMapping}
                            name={
                                currentMappingEdit
                                    ? currentMappingEdit.name
                                    : ''
                            }
                            categories={
                                currentMappingEdit
                                    ? currentMappingEdit.categories
                                    : []
                            }
                        />
                    </Box>
                )}
                <TableContainer
                    component={Paper}
                    sx={{ borderRadius: 2, boxShadow: 3, marginTop: 2 }}
                >
                    <Table
                        sx={{ minWidth: 650, tableLayout: 'fixed' }}
                        aria-label='glossary table'
                    >
                        <TableHead sx={{ bgcolor: '#f5f5f5' }}>
                            <TableRow>
                                <TableCell>
                                    <strong>Name</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Description</strong>
                                </TableCell>
                                {contractorMappings.map(mapping => (
                                    <TableCell key={mapping.id}>
                                        <strong>{mapping.name}</strong>
                                        <IconButton
                                            color='error'
                                            onClick={() =>
                                                handleClickOpen(mapping.id)
                                            }
                                            aria-label='delete'
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                        <IconButton
                                            color='primary'
                                            onClick={() =>
                                                handleEditMapping(mapping)
                                            }
                                            size='small'
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>Confirm Deletion</DialogTitle>
                                <DialogContent>
                                    Are you sure you want to delete this
                                    mapping?
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color='primary'
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handleDelete}
                                        color='secondary'
                                    >
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </TableHead>
                        <TableBody>
                            {displaySkills(skillTypes[selectedTab]).map(
                                skill => (
                                    <TableRow key={skill.id}>
                                        <TableCell>{skill.name}</TableCell>
                                        <TableCell>
                                            {skill.description}
                                        </TableCell>
                                        {contractorMappings.map(mapping => {
                                            const competencies =
                                                mapping.competencies;

                                            let competency = competencies?.find(
                                                comp =>
                                                    comp.skill?.id === skill.id
                                            );

                                            const selectedCategory =
                                                competency?.category || '';
                                            const isCategoryValid =
                                                mapping.categories.includes(
                                                    selectedCategory
                                                );

                                            return (
                                                <TableCell
                                                    key={`${skill.id}-${mapping.id}`}
                                                >
                                                    <Select
                                                        value={selectedCategory}
                                                        onChange={e =>
                                                            handleChangeCategory(
                                                                skill.id,
                                                                mapping.id,
                                                                e.target.value
                                                            )
                                                        }
                                                        fullWidth
                                                        displayEmpty
                                                        error={!isCategoryValid}
                                                    >
                                                        <MenuItem
                                                            value=''
                                                            disabled
                                                        >
                                                            <em>None</em>
                                                        </MenuItem>
                                                        {!isCategoryValid &&
                                                            selectedCategory && (
                                                                <MenuItem
                                                                    value={
                                                                        selectedCategory
                                                                    }
                                                                    style={{
                                                                        color: 'red',
                                                                    }}
                                                                >
                                                                    {
                                                                        selectedCategory
                                                                    }
                                                                </MenuItem>
                                                            )}

                                                        {mapping.categories.map(
                                                            category => (
                                                                <MenuItem
                                                                    key={
                                                                        category
                                                                    }
                                                                    value={
                                                                        category
                                                                    }
                                                                >
                                                                    {category}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Select>
                                                    {!isCategoryValid &&
                                                        selectedCategory && (
                                                            <div
                                                                style={{
                                                                    color: 'red',
                                                                    fontSize:
                                                                        '0.8em',
                                                                }}
                                                            >
                                                                Warning: This
                                                                category no
                                                                longer exists.
                                                            </div>
                                                        )}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component='div'
                        count={filteredSkills(skillTypes[selectedTab]).length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            </Box>
        </Container>
    );
}
