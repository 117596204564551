import {
    Skill,
    ContractorMapping,
    Competency,
    MappingData,
    CompetencyData,
} from '../../interfaces/rest';

const API_BASE_URL = import.meta.env.VITE_BACKEND_BASE_URL;

const getAuthHeaders = (token: string) => ({
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
});

export const fetchSkills = async (token: string): Promise<Skill[]> => {
    const response = await fetch(`${API_BASE_URL}api/skills`, {
        method: 'GET',
        headers: getAuthHeaders(token),
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const jsonResponse = await response.json();
    return jsonResponse._embedded.skills;
};

export const saveSkill = async (token: string, skill: Skill): Promise<void> => {
    const response = await fetch(`${API_BASE_URL}api/skills/${skill.id}`, {
        method: 'PUT',
        headers: getAuthHeaders(token),
        body: JSON.stringify(skill),
    });

    if (!response.ok) {
        throw new Error('Failed to update the skill');
    }
};

export const deleteSkill = async (
    token: string,
    skillId: number
): Promise<void> => {
    const response = await fetch(`${API_BASE_URL}api/skills/${skillId}`, {
        method: 'DELETE',
        headers: getAuthHeaders(token),
    });

    if (!response.ok) {
        throw new Error('Failed to delete the skill');
    }
};

export const createSkill = async (
    token: string,
    skill: Omit<Skill, 'id'>
): Promise<Skill> => {
    const response = await fetch(`${API_BASE_URL}api/skills`, {
        method: 'POST',
        headers: getAuthHeaders(token),
        body: JSON.stringify(skill),
    });

    if (!response.ok) {
        throw new Error('Failed to add skill');
    }

    const createdSkill = await response.json();
    return createdSkill;
};

export const createContractorMapping = async (
    token: string,
    mapping: Omit<ContractorMapping, 'id'>
): Promise<ContractorMapping> => {
    const response = await fetch(`${API_BASE_URL}api/contractorMappings`, {
        method: 'POST',
        headers: getAuthHeaders(token),
        body: JSON.stringify(mapping),
    });

    if (!response.ok) {
        throw new Error('Failed to add Mapping');
    }
    const createdMapping = await response.json();
    return createdMapping;
};

export const fetchMappings = async (
    token: string
): Promise<ContractorMapping[]> => {
    const response = await fetch(
        `${API_BASE_URL}api/contractorMappings?projection=fullContractorMapping`,
        {
            method: 'GET',
            headers: getAuthHeaders(token),
        }
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const jsonResponse = await response.json();
    return jsonResponse._embedded.contractorMappings;
};

export const patchContractorMapping = async (
    token: string,
    mapping: ContractorMapping
): Promise<void> => {
    const formattedMapping = convertToMappingData(mapping);
    const response = await fetch(
        `${API_BASE_URL}api/contractorMappings/${mapping.id}`,
        {
            method: 'PATCH',
            headers: {
                ...getAuthHeaders(token),
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formattedMapping),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update the contractor mapping');
    }
};

export const deleteContractorMapping = async (
    token: string,
    ContractorMappingId: number
): Promise<void> => {
    const response = await fetch(
        `${API_BASE_URL}api/contractorMappings/${ContractorMappingId}`,
        {
            method: 'DELETE',
            headers: getAuthHeaders(token),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to delete the skill');
    }
};

export const createCompetency = async (
    token: string,
    category: string,
    skillid: number
): Promise<Competency> => {
    const competencyData = convertToCompetencyData(category, skillid);
    const response = await fetch(
        `${API_BASE_URL}api/competencies?projection=fullCompetency`,
        {
            method: 'POST',
            headers: getAuthHeaders(token),
            body: JSON.stringify(competencyData),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to add Competency');
    }

    const createdCompetency = await response.json();
    return createdCompetency;
};

export const saveCompetency = async (
    token: string,
    competency: Competency
): Promise<void> => {
    const response = await fetch(
        `${API_BASE_URL}api/competencies/${competency.id}`,
        {
            method: 'PUT',
            headers: getAuthHeaders(token),
            body: JSON.stringify(competency),
        }
    );

    if (!response.ok) {
        throw new Error('Failed to update the skill');
    }
};

export const convertToMappingData = (
    contractorMapping: ContractorMapping
): MappingData => {
    return {
        id: contractorMapping.id,
        name: contractorMapping.name,
        competencies: contractorMapping.competencies.map(
            comp => `api/competencies/${comp.id}`
        ),
        categories: contractorMapping.categories,
        template: contractorMapping.template,
    };
};

export const convertToCompetencyData = (
    category: string,
    skillid: number
): CompetencyData => {
    return {
        category: category,
        skill: `${API_BASE_URL}api/skills/${skillid}`,
    };
};
