import { Box, Paper, Stack } from '@mui/material';
import { Textfield } from './Textfield';
import { PersonalDataInputProps } from './PersonalDataInputProps';
import { useFormContext } from 'react-hook-form';

export const PersonalData: React.FC<PersonalDataInputProps> = ({
    employee,
    control,
}) => {
    const {
        formState: { errors },
    } = useFormContext();

    console.log(errors);

    return (
        <Paper elevation={2} sx={{ p: 4 }}>
            <Box mb={4}>
                <Stack spacing={2} direction='row'>
                    <Textfield
                        name='title'
                        control={control}
                        label='Anrede'
                        defaultValue={employee?.title}
                        rules={{ required: 'Anrede darf nicht leer sein' }}
                        select
                        options={[
                            { value: 'Herr', label: 'Herr' },
                            { value: 'Frau', label: 'Frau' },
                            { value: 'Divers', label: 'Divers' },
                        ]}
                        sx={{ minWidth: '120px' }}
                    />
                    <Textfield
                        name='firstName'
                        control={control}
                        label='Vorname'
                        defaultValue={employee?.firstName}
                        rules={{ required: 'Vorname darf nicht leer sein' }}
                    />

                    <Textfield
                        name='lastName'
                        control={control}
                        label='Nachname'
                        defaultValue={employee?.lastName}
                        rules={{ required: 'Nachname darf nicht leer sein' }}
                    />
                </Stack>
            </Box>

            <Stack spacing={3} direction='column'>
                <Textfield
                    name='yearOfBirth'
                    control={control}
                    label='Jahrgang'
                    defaultValue={employee?.yearOfBirth}
                    rules={{ required: 'Jahrgang darf nicht leer sein' }}
                />

                <Textfield
                    name='nationality'
                    control={control}
                    label='Nationalität'
                    defaultValue={employee?.nationality}
                    rules={{ required: 'Nationalität darf nicht leer sein' }}
                />

                <Textfield
                    name='workExperience'
                    control={control}
                    label='Arbeitserfahrung'
                    defaultValue={employee?.workExperience}
                    rules={{
                        required: 'Arbeitserfahrung darf nicht leer sein',
                    }}
                />

                <Textfield
                    name='keyAspects'
                    control={control}
                    label='Schwerpunkte'
                    defaultValue={employee?.keyAspects}
                />
                <Textfield
                    name='qualification'
                    control={control}
                    label='Qualifikation'
                    defaultValue={employee?.qualification}
                    multiline
                    minRows={3}
                    rules={{ required: 'Qualifikation darf nicht leer sein' }}
                />
                <Textfield
                    name='certifications'
                    control={control}
                    label='Zertifizierungen'
                    defaultValue={employee?.certifications}
                />
                <Textfield
                    name='training'
                    control={control}
                    label='Fortbildungen'
                    defaultValue={employee?.training}
                />
            </Stack>
        </Paper>
    );
};
