import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Select,
    MenuItem,
    Box,
    TextField,
} from '@mui/material';
import { RateableResult, ContractorMapping } from '../../interfaces/rest';
import { getMappingRef, MappingRef } from '../../interfaces/contractorMappingRef';

interface RateableResultsTableProps {
    rateableResults: RateableResult[];
    mappings: ContractorMapping[];
    onExport: (rateableResultId: number, mappingRef: MappingRef) => void;
    onRatingUpdate: (rateableResultId: number, rating: number) => void;
}

const RateableResultsTable: React.FC<RateableResultsTableProps> = ({
    rateableResults,
    mappings,
    onExport,
    onRatingUpdate,
}) => {
    const [mappingSelections, setMappingSelections] = useState<{
        [resultId: number]: MappingRef;
    }>({});

    const [ratingValues, setRatingValues] = useState<{
        [resultId: number]: number;
    }>({});

    const handleMappingChange = (resultId: number, mappingRef: MappingRef) => {
        setMappingSelections(prevSelections => ({
            ...prevSelections,
            [resultId]: mappingRef,
        }));
    };

    const handleRatingChange = (resultId: number, rating: number) => {
        setRatingValues(prevValues => ({
            ...prevValues,
            [resultId]: rating,
        }));
        onRatingUpdate(resultId, rating);
    };

    const handleExportClick = (resultId: number, employeeId: number) => {
        const selectedMapping = mappingSelections[resultId];
        if (!selectedMapping) {
            alert('Please select a mapping');
            return;
        }
        onExport(employeeId, selectedMapping);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Score</TableCell>
                    <TableCell>Rating (1-10)</TableCell>
                    <TableCell>Export</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rateableResults.map((result: RateableResult) => (
                    <TableRow key={result.id}>
                        <TableCell>{result.id}</TableCell>
                        <TableCell>
                            {result.employeeSnapshot.firstName}
                        </TableCell>
                        <TableCell>
                            {result.employeeSnapshot.lastName}
                        </TableCell>
                        <TableCell>{result.employeeSnapshot.email}</TableCell>
                        <TableCell>{result.score}</TableCell>
                        <TableCell>
                            <TextField
                                type='number'
                                inputProps={{ min: 1, max: 10 }}
                                value={ratingValues[result.id] || ''}
                                onChange={event =>
                                    handleRatingChange(
                                        result.id,
                                        parseInt(event.target.value, 10)
                                    )
                                }
                            />
                        </TableCell>
                        <TableCell>
                            <Box display='flex' alignItems='center'>
                                <Select
                                    value={mappingSelections[result.id] || ''}
                                    onChange={event =>
                                        handleMappingChange(
                                            result.id,
                                            event.target.value as MappingRef,
                                        )
                                    }
                                    displayEmpty
                                    style={{
                                        minWidth: 150,
                                        marginRight: 8,
                                    }}
                                >
                                    <MenuItem value=''>
                                        <em>Select Mapping</em>
                                    </MenuItem>
                                    {mappings.map(mapping => (
                                        <MenuItem
                                            key={mapping.id}
                                            value={getMappingRef(mapping)}
                                        >
                                            {mapping.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() =>
                                        handleExportClick(
                                            result.id,
                                            result.employeeId
                                        )
                                    }
                                    disabled={!mappingSelections[result.id]}
                                >
                                    Export
                                </Button>
                            </Box>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default RateableResultsTable;
