import { createRoot } from 'react-dom/client';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';
import { User } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import { StrictMode } from 'react';
import App from './App';

const authDomain = import.meta.env.VITE_OAUTH_AUTHORITY_DOMAIN ?? '';
const clientId = import.meta.env.VITE_OAUTH_CLIENT_ID ?? '';
const redirectUri = import.meta.env.VITE_OAUTH_CALLBACK_URL ?? '';
const baseUrl = import.meta.env.VITE_BASE_URL ?? '/';

const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState({}, document.title, `${baseUrl}index.html`);
};

const oidcConfig = {
    authority: authDomain,
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: `openid profile ${clientId}/.default`,
    onSigninCallback,
};

const CONTAINER = document.getElementById('root');

if (CONTAINER === null) {
    throw new Error('Element #root not found');
}

const ROOT = createRoot(CONTAINER);

ROOT.render(
    <StrictMode>
        <AuthProvider {...oidcConfig}>
            <ScopedCssBaseline enableColorScheme>
                <App />
            </ScopedCssBaseline>
        </AuthProvider>
    </StrictMode>
);
