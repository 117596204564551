import React from 'react';

export default function Main(): React.ReactElement {
    return (
        <>
            <h1>Willkommen beim Profile Projekt</h1>
            <p>
                Mit dieser App sollen in Zukunft Profile erstellt und verwaltet
                werden.
            </p>
        </>
    );
}
