import { ContractorMapping } from './rest';

// changing the export parameter for contractorMapping from name to id turned
// out to be a PITA, so this type "MappingRef"erence is the single point.

export type MappingRef = number

export function getMappingRef(contractorMapping: ContractorMapping): MappingRef {
    return contractorMapping.id;
}
