import React from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import Profile from './components/profile';

export default function App(): React.ReactElement {
    const oauthConfigAvailable =
        import.meta.env.VITE_OAUTH_AUTHORITY_DOMAIN !== undefined &&
        import.meta.env.VITE_OAUTH_CLIENT_ID !== undefined &&
        import.meta.env.VITE_OAUTH_CALLBACK_URL !== undefined;

    console.log('oauth', oauthConfigAvailable);
    const auth = useAuth();
    const [isSalesUser, setIsSalesUser] = React.useState<boolean | null>(null);

    // automatically sign-in
    React.useEffect(() => {
        if (
            oauthConfigAvailable &&
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading
        ) {
            window.localStorage.setItem('href', window.location.href);
            auth.signinRedirect();
        }
    }, [
        auth,
        auth.isAuthenticated,
        auth.activeNavigator,
        auth.isLoading,
        auth.signinRedirect,
        oauthConfigAvailable,
    ]);
    // assign sales role if authorized
    React.useEffect(() => {
        if (
            oauthConfigAvailable &&
            auth.isAuthenticated &&
            isSalesUser === null
        ) {
            fetch(
                `${import.meta.env.VITE_BACKEND_BASE_URL}api/user/isSalesUser`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`,
                    },
                }
            )
                .then(response => response.json())
                .then(data => setIsSalesUser(data))
                .catch(error => {
                    console.error('Error fetching user role:', error);
                    setIsSalesUser(false);
                });
        }
    }, [
        auth.isAuthenticated,
        isSalesUser,
        oauthConfigAvailable,
        auth.user?.access_token,
        console.log('isSalesuser: ', isSalesUser),
    ]);

    if (oauthConfigAvailable) {
        switch (auth.activeNavigator) {
            case 'signinRedirect':
            case 'signinSilent':
                console.log('Signing you in...');
                break;
            case 'signoutRedirect':
                console.log('Signing you out...');
                break;
            case undefined:
                break;
            default:
                console.log(
                    'unknown auth.activeNavigator',
                    auth.activeNavigator
                );
        }
    }

    if (oauthConfigAvailable && auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (oauthConfigAvailable && auth.error) {
        return (
            <div>
                Authentication error, please try to reload!
                <br /> {auth.error.message}
            </div>
        );
    }

    if (oauthConfigAvailable && !auth.isAuthenticated) {
        return <div>Unable to log in, please try to reload.</div>;
    }

    if (oauthConfigAvailable) {
        console.log(auth.user);
        const decoded = jwtDecode(auth.user?.access_token ?? '');
        console.log(decoded);
        fetch(`${import.meta.env.VITE_BACKEND_BASE_URL}api/user/userProfile`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${auth.user?.access_token}` },
        }).then(r => r.text().then(text => console.log(text)));
    }

    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <Profile isSalesUser={isSalesUser} />
        </QueryClientProvider>
    );
}
