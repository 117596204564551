import React from 'react';
import ExportPage from './exportPage';

export default function Main(): React.ReactElement {
    return (
        <>
            <ExportPage />;
        </>
    );
}
