import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FieldInputProps } from '../FieldInputProps';
import MenuItem from '@mui/material/MenuItem';

export const Textfield: React.FC<FieldInputProps> = ({
    name,
    control,
    label,
    defaultValue,
    rules,
    select,
    options,
    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({
                field: { onChange, value, ref },
                fieldState: { error },
            }) => (
                <TextField
                    helperText={error ? error.message : null}
                    size='small'
                    error={!!error}
                    onChange={onChange}
                    inputRef={ref}
                    value={value}
                    fullWidth
                    label={label}
                    variant='outlined'
                    select={select}
                    {...props}
                >
                    {select &&
                        options?.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                </TextField>
            )}
        />
    );
};
