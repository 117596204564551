import React from 'react';
import SearchForm from './searchForm';

export default function Main(): React.ReactElement {
    return (
        <>
            <SearchForm />;
        </>
    );
}
