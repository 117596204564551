import { useState, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Toolbar,
    Typography,
    AppBar,
    Button,
    Box,
    IconButton,
    Menu,
    MenuList,
    MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../ressources/logo.png';

const pages = [
    { name: 'Home', id: 'home', salesOnly: false },
    { name: 'Profile', id: 'profile', salesOnly: false },
    { name: 'Glossar', id: 'glossar', salesOnly: false },
    { name: 'Verwaltung', id: 'verwaltung', salesOnly: true },
    { name: 'Export', id: 'export', salesOnly: true },
    { name: 'Suche', id: 'search', salesOnly: true },
];

interface HeaderProps {
    isSalesUser: boolean | null;
}

export default function Header({
    isSalesUser,
}: HeaderProps): React.ReactElement {
    const [anchorNav, setAnchorNav] = useState<null | HTMLElement>(null);
    const openMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorNav(event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorNav(null);
    };

    const visiblePages = pages.filter(page => !page.salesOnly || isSalesUser);

    return (
        <AppBar position='static'>
            <Toolbar>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='logo'
                    sx={{ display: { xs: 'none', md: 'flex' } }}
                >
                    <img src={logo} alt='Company Logo' className='logo' />
                </IconButton>
                <Typography
                    variant='h6'
                    component='div'
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                >
                    Profile App
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    {visiblePages.map(page => (
                        <Button
                            key={page.id}
                            component={RouterLink}
                            to={'/' + page.id}
                            color='inherit'
                        >
                            {page.name}
                        </Button>
                    ))}
                </Box>
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        key='Icon2'
                        size='large'
                        edge='start'
                        color='inherit'
                        onClick={openMenu}
                    >
                        {' '}
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        open={Boolean(anchorNav)}
                        onClose={closeMenu}
                        sx={{ display: { xs: 'flex', md: 'none' } }}
                    >
                        <MenuList>
                            {visiblePages.map(page => (
                                <MenuItem
                                    key={page.id}
                                    component={RouterLink}
                                    to={'/' + page.id}
                                    color='inherit'
                                >
                                    {page.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Box>
                <IconButton
                    size='large'
                    edge='start'
                    color='inherit'
                    aria-label='logo'
                    sx={{ display: { xs: 'flex', md: 'none' } }}
                >
                    <img src={logo} alt='Company Logo' className='logo' />
                </IconButton>
                <Typography
                    variant='h6'
                    component='div'
                    sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                >
                    Profile App
                </Typography>
            </Toolbar>
        </AppBar>
    );
}
