import React, { useState, useEffect } from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    Chip,
    IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

interface AddContractorMappingFormProps {
    onAddMapping: (name: string, categories: string[]) => void;
    alertMessage?: string | null;
    name?: string;
    categories?: string[];
}

const AddContractorMappingForm: React.FC<AddContractorMappingFormProps> = ({
    onAddMapping,
    alertMessage,
    name: nameProp = '',
    categories: categoriesProp = [],
}) => {
    const [name, setName] = useState<string>(nameProp);
    const [categoryInput, setCategoryInput] = useState<string>('');
    const [categories, setCategories] = useState<string[]>(categoriesProp);
    const [warning, setWarning] = useState<string | null>(null);

    // Update name and categories on Prop change when editing
    useEffect(() => {
        setName(nameProp);
        setCategories(categoriesProp);
    }, [nameProp, categoriesProp]);

    const handleAddCategory = () => {
        const trimmedCategory = categoryInput.trim();

        if (trimmedCategory && !categories.includes(trimmedCategory)) {
            setCategories([...categories, trimmedCategory]);
            setWarning(null);
        } else if (categories.includes(trimmedCategory)) {
            setWarning(`Category "${trimmedCategory}" already exists.`);
        }

        setCategoryInput('');
    };

    const handleDeleteCategory = (category: string) => {
        setCategories(categories.filter(c => c !== category));
    };

    const handleSubmit = () => {
        onAddMapping(name, categories);
        setName('');
        setCategories([]);
    };

    return (
        <Box sx={{ marginBottom: 2 }}>
            <Typography variant='h6'>Add New Contractor Mapping</Typography>
            <TextField
                label='Mapping Name'
                value={name}
                onChange={e => setName(e.target.value)}
                fullWidth
                margin='normal'
                error={!!alertMessage}
                helperText={alertMessage}
            />
            <Box
                sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
            >
                <TextField
                    label='Add Category'
                    value={categoryInput}
                    onChange={e => setCategoryInput(e.target.value)}
                    onKeyPress={e => e.key === 'Enter' && handleAddCategory()}
                    margin='normal'
                    fullWidth
                    error={!!warning}
                    helperText={warning}
                />
                <IconButton color='primary' onClick={handleAddCategory}>
                    <AddIcon />
                </IconButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                    marginBottom: 2,
                }}
            >
                {categories.map(category => (
                    <Chip
                        key={category}
                        label={category}
                        onDelete={() => handleDeleteCategory(category)}
                        deleteIcon={<DeleteIcon />}
                        color='primary'
                    />
                ))}
            </Box>
            <Button variant='contained' color='primary' onClick={handleSubmit}>
                {nameProp ? 'Save Changes' : 'Add Mapping'}
            </Button>
        </Box>
    );
};

export default AddContractorMappingForm;
