import React, { useState } from 'react';
import { Box, Button, TextField, MenuItem } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { Skill, SkillType } from '../../interfaces/rest';
import { createSkill } from './skillApi';

interface AddSkillFormProps {
    onAddSkill: (skill: Skill) => void;
    existingSkills: Skill[];
}

export default function AddSkillForm({
    onAddSkill,
    existingSkills,
}: AddSkillFormProps) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [type, setType] = useState<SkillType>('IT');
    const [error, setError] = useState<string | null>(null);
    const auth = useAuth();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const trimmedName = name.trim();
        const trimmedDescription = description.trim();

        const newSkill: Omit<Skill, 'id'> = {
            name: trimmedName,
            description: trimmedDescription,
            type,
        };

        const duplicate = existingSkills.some(
            skill =>
                skill.name.trim().toLowerCase() === trimmedName.toLowerCase()
        );

        if (duplicate) {
            setError('A skill with this name already exists.');
            return;
        }

        try {
            const createdSkill = await createSkill(
                auth.user?.access_token || '',
                newSkill
            );
            onAddSkill(createdSkill);

            setName('');
            setDescription('');
            setType('IT');
            setError(null);
        } catch (error) {
            console.error('Error adding skill:', error);
            setError('There was an error adding the skill.');
        }
    };

    return (
        <Box
            component='form'
            sx={{
                marginBottom: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
            }}
            onSubmit={handleSubmit}
        >
            <TextField
                label='Name'
                variant='outlined'
                value={name}
                onChange={e => setName(e.target.value)}
                size='small'
                sx={{ marginRight: 2 }}
                required
                error={!!error}
                helperText={error}
            />
            <TextField
                label='Description'
                variant='outlined'
                value={description}
                onChange={e => setDescription(e.target.value)}
                multiline
                rows={4}
                sx={{ marginRight: 2 }}
            />
            <TextField
                label='Type'
                variant='outlined'
                value={type}
                onChange={e => setType(e.target.value as SkillType)}
                select
                sx={{ marginRight: 2 }}
                required
            >
                <MenuItem value='IT'>IT</MenuItem>
                <MenuItem value='CONSULTING'>Consulting</MenuItem>
                <MenuItem value='LANGUAGE'>Language</MenuItem>
            </TextField>
            <Button type='submit' variant='contained' color='primary'>
                Add Skill
            </Button>
        </Box>
    );
}
